
import { defineComponent } from "vue";
import BaseHeader from "@/components/BaseHeader2.vue";
import BaseTextarea from "@/components/BaseTextarea.vue";
import TextButton from "@/components/common/TextButton.vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
  name: "ReportUpload",
  components: {
    BaseHeader,
    TextButton,
    BaseTextarea,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      detail: null,
    };
  },
  validations: {
    detail: { required },
  },
  methods: {
    submit() {
      this.$axios
        .post("/board/inquery", {
          detail: this.detail,
        })
        .then(() => {
          this.$flutter.callHandler("showToast", {
            message: "등록되었습니다.",
            color: "success",
          });
          this.$router.replace("/my");
        });
    },
  },
  beforeMount() {
    this.$flutter.callHandler("hideGnb");
  },
  beforeUnmount() {
    this.$flutter.callHandler("showGnb");
  },
});
